import type { LoaderFunctionArgs, MetaFunction } from "react-router";
import { Link } from "react-router";
import { AppLayout } from "~/components/layouts/appLayout";
import { Button } from "~/components/ui/button";
import { SimpleWeglotSwitcher } from "~/hooks/weglot";
import { requireGuest } from "~/services/auth.server";

export const meta: MetaFunction = () => {
  return [{ title: "マイコミュへようこそ" }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await requireGuest(request);
  return null;
};

export default function Index() {
  return (
    <AppLayout className="flex flex-col pb-2">
      <div className="flex flex-1 items-center justify-center">
        <div className="mt-12 flex flex-col items-center">
          <img
            src="/common/logo-my-commu.svg"
            width="141"
            height="213"
            alt="マイコミュ by AsMama"
          />

          <SimpleWeglotSwitcher className="mt-6" />
        </div>
      </div>

      <div className="mx-8 my-10">
        <div className="mx-8 mb-4">
          <img
            src="/campaign/2501-symbol-renew.svg"
            width="504"
            height="147"
            alt=""
          />
        </div>

        <Link to="/auth/signin">
          <Button className="w-full font-bold">ログイン</Button>
        </Link>

        <Link to="/auth/signup">
          <Button variant="secondary" className="mt-4 w-full font-bold">
            新規アカウント登録
          </Button>
        </Link>
      </div>
    </AppLayout>
  );
}
